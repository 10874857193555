export const setLSValue = (key: string, value: string) => {
    try {
        const localStorage = window?.localStorage;
        if (localStorage) {
            localStorage.setItem(key, value);
        }
    } catch (err) {}
};

export const getLSValue = (key: string): string | null => {
    try {
        const localStorage = window?.localStorage;
        if (localStorage) {
            return localStorage.getItem(key);
        } else {
            return null;
        }
    } catch (err) {
        return null;
    }
};

export const removeLSKey = (key: string) => {
    try {
        const localStorage = window?.localStorage;
        if (localStorage) {
            localStorage.removeItem(key);
        }
    } catch (error) {}
};
