import { useEffect, useState } from 'react';

const useTabHelper = () => {
    const [popupBlocker, setPopupBlocker] = useState({
        isPopUpBlocked: false,
        popupBlockerCallback: () => {},
    });
    const [tabRef, setTabRef] = useState<Window | null>(null);
    let timerId: NodeJS.Timeout;
    const checkTabStatus = (tab: Window | null | undefined, failureCallback: () => void) => {
        checkOriginTabStatus();
        clearInterval(timerId);
        timerId = setInterval(() => {
            if (tab && tab.closed) {
                failureCallback();
                clearTabStatus();
            }
        }, 600);
    };

    const checkOriginTabStatus = () => {
        window.onmessage = (event) => {
            if (event.data && event.data.useActionCompleted) {
                clearTabStatus();
            }
        };
    };

    const openUrlInNewTab = (
        redirectUrl: string,
        onTabOpen: () => void,
        onTabClose: () => void,
        method: 'get' | 'post',
        isIframe: boolean = false,
        params?: Record<string, string>
    ) => {
        if (method === 'get') {
            let retryFunction = () => {
                const tabRef = window.open(redirectUrl, '_blank');
                setTabRef(tabRef);
                if (tabRef) {
                    onTabOpen();
                    checkTabStatus(tabRef, onTabClose);
                } else {
                    setPopupBlocker({ isPopUpBlocked: true, popupBlockerCallback: retryFunction });
                }
            };
            retryFunction();
        } else {
            handleFormPost(redirectUrl, params, isIframe, onTabOpen, onTabClose);
        }
    };

    const handleTabFocus = () => {
        if (tabRef) {
            tabRef.focus();
        }
    };

    const clearTabStatus = () => {
        clearInterval(timerId);
        window.onmessage = null;
    };

    const handleFormPost = (
        redirectUrl: string,
        params: Record<string, string> = {},
        isIframe: boolean = false,
        onTabOpen: () => void,
        onTabClose: () => void
    ) => {
        let windowName = 'superCheckoutNewWindow';
        let form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', redirectUrl);
        if (isIframe) {
            form.setAttribute('target', windowName);
        } else {
            form.setAttribute('target', '_self');
        }
        for (let i in params) {
            if (params.hasOwnProperty(i)) {
                let input = document.createElement('input');
                input.type = 'hidden';
                input.name = i;
                input.value = params[i];
                form.appendChild(input);
            }
        }
        document.body.appendChild(form);
        try {
            if (isIframe) {
                const tabRef = window.open('', windowName);
                setTabRef(tabRef);
                if (tabRef) {
                    onTabOpen();
                    checkTabStatus(tabRef, onTabClose);
                }
                form.target = windowName;
            }
            form.submit();
        } catch (err) {
            setPopupBlocker({
                isPopUpBlocked: true,
                popupBlockerCallback: () => handleFormPost(redirectUrl, params, isIframe, onTabOpen, onTabClose),
            });
            setTabRef(null);
        }
        document.body.removeChild(form);
    };

    useEffect(() => {
        return () => {
            clearTabStatus();
        };
    }, []);

    return { clearTabStatus, tabRef, openUrlInNewTab, handleTabFocus, popupBlocker};
};

export default useTabHelper;
