import { getClassName } from '../../utils';
import Button from '../base/Button';
import Loader from '../Loader';

interface UserDropModaProps {
    onClickYes: () => void;
    onClickNo: () => void;
    title: string;
    headerText: string;
    size?: 'mobile' | 'desktop';
}

const UserDropModal = (props: UserDropModaProps) => {
    const { onClickNo, onClickYes, title, headerText, size = 'mobile' } = props;

    const containerClass = getClassName(
        {
            'max-w-[450px] w-full': size === 'mobile',
            'max-w-[769px]': size === 'desktop',
        },
        'bg-[rgba(0,0,0,0.6)] fixed h-[100dvh] z-[1000] w-full top-0 right-0 bottom-0 left-1/2 -translate-x-1/2'
    );

    const contentClass = getClassName(
        {
            'max-w-[360px] rounded-b-[16px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 pt-[20px] bottom-auto':
                size === 'desktop',
        },
        'rounded-t-[16px] bg-white shadow-card absolute bottom-0 w-full px-[16px] pb-[30px] pt-[20px]'
    );

    return (
        <div className={containerClass}>
            <div className={contentClass}>
                <div className="flex flex-col items-center justify-center gap-[20px]">
                    <Loader centered={false} />
                    <div className="text-center">
                        <p className="text-18 font-robotoBold text-titleDark">{headerText}</p>
                        <p className="text-14 font-robotoRegular text-lightBody">{title}</p>
                    </div>
                    <div className="flex w-full gap-[16px]">
                        <Button
                            onClick={onClickYes}
                            className="flex-1 bg-white border border-solid border-secondaryGreen text-secondaryGreen"
                        >
                            Yes, Cancel
                        </Button>
                        <Button onClick={onClickNo} className="flex-1">
                            No Wait
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDropModal;
