import Cart from '../../types/cart.type';
import { Action } from '../../types/common/reducer.type';
import { HeaderDetails, Plan, PlanConfig } from '../../types/plan.type';
import User from '../../types/user.type';
import { getLSValue } from '../../utils/localstorage.helper';

export enum AppAction {
    UPDATE_LOADING = 'UPDATE_LOADING',
    UPDATE_USER = 'UPDATE_USER',
    UPDATE_CART = 'UPDATE_CART',
    UPDATE_PLAN_CONFIG = 'UPDATE_PLAN_CONFIG',
    UPDATE_HEADER_DETAILS = 'UPDATE_HEADER_DETAILS',
}

export interface AppState {
    loading: boolean;
    user: User | null;
    cart: Cart;
    planConfig: PlanConfig;
    headerDetails: HeaderDetails;
}

const userToken = getLSValue('tk');

export const initialState: AppState = {
    loading: true,
    user: userToken ? { token: userToken } : null,
    cart: {} as Cart,
    planConfig: {
        loading: false,
        plans: [],
        selectedPlan: {} as Plan,
        planType: 'public',
    },
    headerDetails: {
        logoUrl: '',
        loading: false,
        title: '',
    },
};

const { UPDATE_LOADING, UPDATE_CART, UPDATE_USER, UPDATE_PLAN_CONFIG, UPDATE_HEADER_DETAILS } = AppAction;

export const AppReducer = (state: AppState, action: Action<AppState, AppAction>): AppState => {
    const { payload, type } = action;

    switch (type) {
        case UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading,
            } as AppState;

        case UPDATE_CART:
            return {
                ...state,
                cart: payload.cart,
            } as AppState;

        case UPDATE_USER:
            return {
                ...state,
                user: payload.user,
            } as AppState;
        case UPDATE_PLAN_CONFIG:
            return {
                ...state,
                planConfig: payload.planConfig as PlanConfig,
            };
        case UPDATE_HEADER_DETAILS:
            return {
                ...state,
                headerDetails: payload.headerDetails as HeaderDetails,
            };
        default:
            return state;
    }
};
