import { useContext, useState } from 'react';
import useQuery from '../../hooks/useQuery.hook';
import { AppContext } from '../../context/app';
import Shimmer from '../Shimmer';
import { broadcastMessage, getClassName } from '../../utils';
import { ViewType } from '../../types/common/index.type';
import UserDropModal from '../common/user-drop-warning';
import { useWindowSize } from '../../hooks/window-size.helper';

export default function Header() {
    const { width } = useWindowSize();
    const { queryParams } = useQuery();
    const { state, currentView } = useContext(AppContext);
    const { headerDetails } = state;
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const hideHeader = ['/eligibility/rpd'].includes(window.location.pathname);
    const routeHeaderMap: Record<string, string> = {
        '/login': 'Enter Mobile Number',
        '/otp': 'Verify Number',
        '/address':
            queryParams.addressType === 'new_address'
                ? 'Add Delivery Address'
                : queryParams.addressType === 'address_list'
                ? 'Choose Delivery Address'
                : '',
        '/profile': 'Personal Details',
        '/income_verify': 'Bank Details',
        '/plan': 'Choose Payment Plan',
        '/kyc': 'Complete KYC',
        '/auto-debit': 'Set Up EMI Auto Pay',
        '/dp': 'Select Payment Method',
        '/eligibility/profile': 'Personal Details',
        '/eligibility/limits': 'Credit Approved',
        '/order/status': 'Order Processing',
    };
    const headerContainerClass = getClassName(
        {
            'sticky w-full top-0 h-[52px] z-[999999]': currentView === ViewType.IFRAME,
            'shadow-[2px_2px_11px_0px_rgba(1,71,81,0.10)] bg-white': !!headerDetails?.logoUrl && currentView === ViewType.IFRAME,
        },
        'bg-header flex items-center min-[600px]:justify-center'
    );
    const closeWindow = () => {
        broadcastMessage({ closeIframe: true });
    };

    const whiteLabelClass = getClassName(
        {
            'justify-center': !routeHeaderMap[window.location.pathname],
        },
        'flex items-center w-full md:max-w-[450px] md:px-[16px]'
    );
    if (hideHeader) { return null; }
    return (
        <div className={headerContainerClass}>
            {headerDetails.loading ? (
                <div className="w-full h-[48px]">
                    <Shimmer className="h-[48px]" />
                </div>
            ) : (
                <>
                    {headerDetails.logoUrl ? (
                        <div className={whiteLabelClass}>
                            <div className="ml-[16px] md:ml-[0px] w-[36px] h-[36px] flex items-center justify-center rounded-sm border border-solid border-pie">
                                <img src={headerDetails.logoUrl} alt="Header Logo" width={22} height={16} />
                            </div>
                            <p className="ml-[16px] text-primaryGray font-openSansSemiBold text-18 py-[12px]">
                                {routeHeaderMap[window.location.pathname] || headerDetails.title}
                            </p>
                        </div>
                    ) : (
                        <p className="ml-[16px] text-primaryGray font-openSansSemiBold text-18 py-[12px]">
                            {routeHeaderMap[window.location.pathname] || (
                                <img src="/images/snapmint.svg" alt="Default Header" />
                            )}
                        </p>
                    )}
                </>
            )}
            {currentView === ViewType.IFRAME && (
                <img
                    src="/images/cross.svg"
                    className="absolute w-[24px] h-[24px] right-16 cursor-pointer"
                    alt="X"
                    onClick={() => {
                        setShowCancelPopup(true);
                    }}
                />
            )}
            {showCancelPopup && (
                <UserDropModal
                    size={width < 769 ? 'mobile' : 'desktop'}
                    onClickYes={() => closeWindow()}
                    onClickNo={() => setShowCancelPopup(false)}
                    title="Your EMI purchase is ongoing, Are you sure you want to cancel the purchase?"
                    headerText="Cancel EMI Purchase"
                />
            )}
        </div>
    );
}
