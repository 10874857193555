const EligibilityHeader = () => {
    const titles = [
        '<span>0% Interest <br />Installments</span>',
        '<span>0 Extra <br />Cost</span>',
        '<span>UPI & Cards <br />accepted</span>',
    ]
    const ignoreEligibilityHeader = ['/eligibility/limits', '/login', '/error', "/eligibility/rpd"].includes(window.location.pathname);

    if (ignoreEligibilityHeader) {
        return null;
    }
    return (
        <div className="bg-lightGrayOutline rounded-lg p-[1px]">
            <div className="bg-white rounded-t-lg">
                <div className="pt-[12px] pb-[12px]">
                <p className="text-center text-13 font-robotoBold text-titleDark">
                    Get Instant Credit & Pay in Easy EMIs{' '}
                </p>
                    <div className="flex justify-center px-[12px] items-center mt-[12px] gap-[6px]">
                        {[3, 6, 9].map((month, i) => {
                            return (
                                <>
                                    <div className="text-primaryGray text-14 font-robotoBold px-[14px] py-[8px] bg-[#FFFACF] rounded-full">
                                        {month} Months
                                    </div>
                                    {i < 2 && <p className="font-robotoBold text-lightBody text-14"> / </p>}
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center gap-[24px] py-[12px]">
                {titles.map((title, i) => {
                    return (
                        <>
                            <div
                                className="text-center text-lightBody text-12 font-robotoMedium"
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                            {i <= 2 && <div className="h-[20px] w-[1px] eligibility-card-line" />}
                        </>
                    );
                })}
            </div>
        </div>
    );
};

export default EligibilityHeader;
