import useRestClient from '../hooks/rest.hook';
import useQuery from '../hooks/useQuery.hook';
import { PollingRes } from '../types/common/response.type';

const useRpdSvc = () => {
    const restClient = useRestClient();
    const { queryParams } = useQuery();
    const { merchantId: mid } = queryParams;
    const getRpdUrl = (): Promise<{ upiLink: string }> => {
        return restClient.get(`/eligibility/rpd/initiate?mid=${mid}`);
    };

    const getRpdStatus = (): Promise<PollingRes> => {
        return restClient.get(`/eligibility/rpd/status?mid=${mid}`);
    };

    return {
        getRpdStatus,
        getRpdUrl,
    };
};

export default useRpdSvc;
