export interface IEmiPercentage {
    dp: number;
    emi: number;
    tenure: number;
    orderValue: number;
}

export enum ViewType {
    WEB = 'WEB',
    WEBVIEW = 'WEBVIEW',
    IFRAME = 'IFRAME',
}

export enum OriginType {
    WEB = 'WEB',
    IFRAME = 'IFRAME',
}