import { createContext, useContext, useEffect, useState } from 'react';
import { AppContext } from '../app';
import { usePolling } from '../../hooks/poll.hook';
import useRpdSvc from '../../services/rpd.svc';
import { isIOSDevice } from '../../utils';
import useQuery from '../../hooks/useQuery.hook';

interface RpdContextType {
    loading: boolean;
    isPolling: boolean;
    onPaymentMethodsClick(paymentMethod: string): void;
    showUserDropModal: boolean;
    setShowUserDropModal(showUserDropModal: boolean): void;
    stopPolling(): void;
    beginPolling(): void;
    showCancelVerification(): void;
}

export const RpdContext = createContext<RpdContextType>({} as RpdContextType);

export const RpdProvider = ({ children }: { children: React.ReactNode }) => {
    const { queryParams } = useQuery();
    const { successUrl, failureUrl, merchantId: mid } = queryParams;
    const [loading, setLoading] = useState(false);
    const [showUserDropModal, setShowUserDropModal] = useState(false);
    const [isOverLayOpen, setOverlayOpen] = useState(false);
    const [upiData, setUpiData] = useState<{ upiLink: string }>({
        upiLink: '',
    });
    const { getRpdUrl, getRpdStatus } = useRpdSvc();
    const { handleError } = useContext(AppContext);
    const REACT_APP_RPD_POLLING_INTERVAL = Number(process.env.REACT_APP_RPD_POLLING_INTERVAL || 5000);
    const REACT_APP_RPD_POLLING_RETRIES = Number(process.env.REACT_APP_RPD_POLLING_RETRIES || 4);

    const fetchUpiLink = async () => {
        try {
            setLoading(true);
            const { upiLink } = await getRpdUrl();
            setUpiData({
                upiLink,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const showCancelVerification = () => {
        let id = setTimeout(() => {
            setShowUserDropModal(true);
            clearTimeout(id);
        }, 5000);
    };

    const handlePayment = (deepLInk: string) => {
        const a = document.createElement('a');
        a.href = deepLInk;
        try {
            a.click();
            beginPolling();
            showCancelVerification();
        } catch (err) {
            stopPolling();
            setLoading(false);
        }
    };

    const handleOverlay = () => {
        if (!upiData.upiLink) return;
        if (isIOSDevice()) {
            setOverlayOpen(true);
        } else {
            handlePayment(upiData.upiLink);
        }
    };

    const onPaymentMethodsClick = async (paymentMethod: string) => {
        if (!upiData.upiLink) return;
        try {
            let url = '';
            switch (paymentMethod) {
                case 'phonepe':
                    url = upiData.upiLink.replace('upi://', `phonepe://`);
                    break;
                case 'paytm':
                    url = upiData.upiLink.replace('upi://', `paytmmp://`);
                    break;
                case 'gPay':
                    url = upiData.upiLink.replace('upi://', `tez://upi/`);
                    break;
                default:
                    handleOverlay();
                    return;
            }
            handlePayment(url);
            setOverlayOpen(false);
        } catch (error) {
            setOverlayOpen(false);
        }
    };

    const onSuccessPolling = () => {
        window.location.assign(successUrl);
    };

    const onFailurePolling = () => {
        window.location.assign(failureUrl);
    };

    const { beginPolling, stopPolling, isPolling } = usePolling({
        callback: async () => await getRpdStatus(),
        onSuccess: async () => onSuccessPolling(),
        onFailure: async () => onFailurePolling(),
        interval: REACT_APP_RPD_POLLING_INTERVAL,
        maxRetries: REACT_APP_RPD_POLLING_RETRIES,
    });

    useEffect(() => {
        fetchUpiLink();
    }, []);

    const values = {
        loading,
        isPolling,
        onPaymentMethodsClick,
        showUserDropModal,
        setShowUserDropModal,
        stopPolling,
        beginPolling,
        showCancelVerification,
    };
    return <RpdContext.Provider value={values}>{children}</RpdContext.Provider>;
};
