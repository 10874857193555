import { smsTypEnum } from '../context/otp';
import useRestClient from '../hooks/rest.hook';
import useQuery from '../hooks/useQuery.hook';
import Auth from '../types/auth.type';

export default function useAuthSvc() {
    const restClient = useRestClient();
    const { queryParams } = useQuery();
    const { smc } = queryParams;

    async function sendOtp(mobile: string, type?: smsTypEnum): Promise<null> {
        return restClient.post('/pub/auth/otp', { mobile, smsType: type });
    }

    async function login(auth: Auth): Promise<{ tokenId: string }> {
        return restClient.post('/pub/auth/login', auth);
    }

    const decodeSmc = (): Promise<{ token: string }> => {
        return restClient.get(`/pub/auth?smc=${smc}`);
    };

    const logout = (userToken: string): Promise<void> => {
        return restClient.post('/auth/logout', {}, { headers: { Authorization: `Bearer ${userToken}` } });
    };
    return {
        sendOtp,
        login,
        decodeSmc,
        logout,
    };
}
